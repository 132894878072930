import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { TTheme } from "../../types"
import { Container } from "../components/container"
import { H2 } from "../components/headings"
import { NavigationBlock } from "../components/navigation-block"
import { Section } from "../components/section"
import mq from "../theme/mq"
import { imageUrlFor, generateSrcSet, getAspectRatio } from "../lib/image"
import { HeroImage } from "../components/hero-image"
import { Typography } from "../components/typography"

export default function AboutPage() {
	const {
		site,
		content: {
			nodes: [data],
		},
	} = useStaticQuery<any>(graphql`
		fragment BlockFragment on SanityNavigationBlock {
			linkTitle
			link
			description
			image {
				asset {
					fluid(toFormat: JPG, maxWidth: 400) {
						src
						aspectRatio
					}
				}
			}
		}
		query AboutPage {
			site {
				siteMetadata {
					title
				}
			}
			content: allSanityAboutPage(filter: { _id: { eq: "about" } }) {
				nodes {
					tagLine
					description
					image {
						...HeroImageFragment
					}
					informationLinks {
						leftLink {
							...BlockFragment
						}
						rightLink {
							...BlockFragment
						}
					}
				}
			}
		}
	`)

	const { informationLinks, description, tagLine, image } = data

	const imageBuilder = imageUrlFor(image).fit("crop")
	const imageUrl = imageBuilder.url()
	const imageSrcSet = generateSrcSet(
		imageBuilder,
		image.asset.fluid.sizes.split(" ")
	)

	return (
		<>
			<HeroImage
				imageAspectRatio={
					getAspectRatio(
						image.crop,
						image.asset.metadata.dimensions.width,
						image.asset.metadata.dimensions.height
					) || image.asset.fluid.aspectRatio
				}
				imageSrc={imageUrl || image.asset.fluid.src}
				imageSrcSet={imageSrcSet || image.asset.fluid.srcSet}
				imageBackgroundColor={
					image.asset.metadata?.palette?.dominant?.background
				}
				title={`About`}
				description={tagLine}
			/>
			<Container
				css={(theme: TTheme) => ({
					marginTop: theme.spacing.xxxl,
					marginBottom: theme.spacing.xxxl,
					[mq.large]: {
						padding: `0 15%`,
						marginTop: theme.spacing.xxxxl,
						marginBottom: theme.spacing.xxxxl,
					},
					[mq.xxlarge]: {
						padding: `0 18%`,
					},
					[mq.container]: {
						padding: `0 18%`,
					},
				})}
			>
				<H2
					css={(theme: TTheme) => ({
						textAlign: `center`,
						marginBottom: theme.spacing.xl,
						[mq.large]: {
							marginBottom: theme.spacing.xxl,
						},
					})}
				>
					{site.siteMetadata.title}
				</H2>
				<Typography css={{ whiteSpace: `pre-wrap` }}>
					{description}
				</Typography>
			</Container>
			<Container
				css={(theme: TTheme) => ({
					marginBottom: theme.spacing.xxxl,
					[mq.large]: {
						padding: `0 5%`,
					},
					[mq.xlarge]: {
						padding: `0 10%`,
					},
					[mq.container]: {
						padding: `0 10%`,
					},
				})}
			>
				<Section>
					<div
						css={(theme: TTheme) => ({
							margin: `${theme.spacing.xxl} auto`,
							gridTemplateColumns: `repeat(2, 45%)`,
							justifyContent: `space-between`,
							gridGap: `40px`,
							[mq.medium]: {
								display: `grid`,
								maxWidth: `100%`,
								margin: `${theme.spacing.xxxl} auto`,
							},
							[mq.large]: {
								maxWidth: `90%`,
							},
							[mq.xlarge]: {
								maxWidth: `80%`,
							},
							[mq.xxlarge]: {
								maxWidth: `70%`,
							},
						})}
					>
						<NavigationBlock
							linkTo={informationLinks.leftLink.link}
							imageSrc={
								informationLinks.leftLink.image.asset.fluid.src
							}
							title={informationLinks.leftLink.linkTitle}
							description={informationLinks.leftLink.description}
							aspectRatio={492 / 328}
						/>
						<NavigationBlock
							linkTo={informationLinks.rightLink.link}
							imageSrc={
								informationLinks.rightLink.image.asset.fluid.src
							}
							title={informationLinks.rightLink.linkTitle}
							description={informationLinks.rightLink.description}
							aspectRatio={492 / 328}
						/>
					</div>
				</Section>
			</Container>
		</>
	)
}
